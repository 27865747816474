<template>
<div>
    <!-- 客户增加 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">

                <el-form  :model="form_data" label-width="140px" style="margin-bottom: 12px;">
                    <el-form-item label="账号">
                        <el-input v-model="form_data.cpid" :disabled="prop_type == 'upd'" placeholder="请输入账号" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="名称">
                        <el-input v-model="form_data.cpname" placeholder="请输入名称" size="mini"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="接口密码">
                        <el-input v-model="form_data.cppwd" placeholder="请输入接口密码" size="mini"></el-input>
                    </el-form-item>
                   
                    <el-form-item label="客户鉴权IP">
                        <el-input v-model="form_data.ip" placeholder="多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="客户接入方式">
                        <el-radio-group v-model="form_data.interface_type" size="mini" >
                            <el-radio label="1">HTTP</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="回调地址" v-show="form_data.interface_type == 1">
                        <el-input v-model="form_data.url" placeholder="请填写回调地址" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input v-model="form_data.remark" placeholder="" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-row>

    </el-dialog>
</div>
</template>
<script>
import API from '../../../api/api';

export default {
    props:{
        prop_change_i:0,
        prop_type:'',//类型 add:增加  upd:修改
        prop_item:{},//客户对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                
                this.dialog_visible = true;//弹出框是否可见 

                if(this.prop_type === "upd"){//修改
                    this.cur_title = "国际客户修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();

                    }, 1);
                }else{
                    this.cur_title = "国际客户增加";
                }
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头


            form_data:{
                cpid:"",
                cpname:"",//名称
                cppwd:"",//用户接口密码
                ip:"",//客户鉴权ip地址(多个用半角逗号隔开)
                interface_type:"1",//接入方式，0:CMPP；1:HTTP
                url:"",//回调地址
                remark:"",//备注
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        console.log("user_add activated");
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 100);
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        getData(){
            this.form_data.cpid = this.prop_item.cpid;//账号
            this.form_data.cpname = this.prop_item.cpname;//名称
            this.form_data.cppwd = this.prop_item.cppwd;//用户接口密码
            this.form_data.ip = this.prop_item.ip;//客户鉴权ip地址(多个用半角逗号隔开)
            this.form_data.interface_type = this.prop_item.interface_type+"";//接入方式，0:CMPP；1:HTTP
            this.form_data.url = this.prop_item.url;//回调地址
            this.form_data.remark = this.prop_item.remark;//备注
        },

        //提交
        onSubmit(){
            console.log("提交");

            var submit_obj = {};
            if(this.prop_type === "upd"){//修改
                submit_obj.param = "upd";
            }else{//增加
                submit_obj.param = "add";
            }
            submit_obj.cpid = this.form_data.cpid;
            submit_obj.cpname = this.form_data.cpname;//
            submit_obj.cppwd = this.form_data.cppwd;//用户接口密码
            submit_obj.ip = this.form_data.ip;//客户鉴权ip地址(多个用半角逗号隔开)
            submit_obj.interface_type = this.form_data.interface_type;//接入方式，0:CMPP；1:HTTP
            submit_obj.url = this.form_data.url;//回调地址
            submit_obj.remark = this.form_data.remark;//备注 ，在用户统计里显示


            if(API.isEmtry(submit_obj.cpid)){
                this.$message.error("请填写账号");
                return;
            }
            if(API.isEmtry(submit_obj.cpname)){
                this.$message.error("请填写名称");
                return;
            }
            if(API.isEmtry(submit_obj.cppwd)){
                this.$message.error("请填写接口密码");
                return;
            }
            if(submit_obj.interface_type == 1){//接入方式，0:CMPP；1:HTTP
                
            }
            API.Int_UserServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}

/* element 单选框样式调整*/
.cur_dialog >>>.el-radio-group {
    line-height: 30px;
    vertical-align: bottom;
}

</style>